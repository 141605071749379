import { ThemeOptions } from '@material-ui/core';
import logo from './assets/img/logo.svg';
import favicon from './assets/img/favicon.ico';
import appleIcon from './assets/img/icon-256x256.png';
import manifest from './manifest.webmanifest';

export const overrides: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      dark: '#4084ff',
      main: '#4084ff',
      light: '#4084ff',
    },
    secondary: {
      dark: '#c39059',
      main: '#c39059',
      light: '#c39059',
    },
    background: {
      paper: '#070625',
      default: '#070625',
    },
  },
  meta: {
    propertyName: 'Maritime IntelX',
    propertyOwner: 'Maritime IntelX',
    propertyDesc: 'Maritime Intelligence Platform',
    logo,
    favicon,
    manifest,
    appleIcon,
    policies: [],
    loginTerms: 'NULL',
  },
};

export default overrides;
